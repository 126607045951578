import React from 'react';

function Footer() {
  return (
    <footer className="footer">
      <div>
        <a
          href="/retningslinjer_for_personvern"
          rel="noreferrer"
          target="_blank"
        >
          Retningslinjer for personvern
        </a>
        <a href="/vilkar_for_bruk" rel="noreferrer" target="_blank">
          Vilkår for bruk
        </a>
      </div>
      <div className="org">
        <p>Org. Nummer: 933270157</p>
      </div>
    </footer>
  );
}

export default Footer;

import React from 'react';
import Button from '@mui/material/Button';

function Article({
  date,
  articleImage,
  title,
  text,
  newspaperLogo,
  newspaperName,
  buttonText = 'Les mer',
  buttonHref,
  showDivider = true,
}) {
  return (
    <div className="article-container">
      <div>
        <div className="date">{date}</div>
        <img src={articleImage} alt="article" loading="lazy" />
        <div className="card">
          <div className="card-body">
            <h3>{title}</h3>
            <span className="text">{text}</span>
          </div>
          <hr className="horizontal" />
          <div className="bottom-section">
            <div>
              <img
                src={newspaperLogo}
                alt={`${newspaperName} logo`}
                loading="lazy"
              />
              <span className="text">{newspaperName}</span>
            </div>
            <Button
              className="button"
              type="button"
              href={buttonHref}
              target="_blank"
              rel="noopener noreferrer"
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
      {showDivider && <hr className="article-divider horizontal" />}
    </div>
  );
}

export default Article;
